.contact-container {
  padding-top: 80px;
  background-image: url('../TopContent/backgrounds/2.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-top {
  overflow: hidden;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #ddd;
  text-align: left;
}
.contact-top-left {
  float: left;
  width: 75%;
}
.contact-top-left h3 {
  margin-top: 0;
}
.contact-bottom {
  padding: 25px 0 0 0;
  text-align: left;
}
.contact-box input[type="text"],
.contact-box input[type="url"],
.contact-box input[type="password"],
.contact-box select,
.contact-box textarea,
.contact-box textarea.form-control {
  height: 40px;
  margin: 0;
  padding: 0 10px;
  vertical-align: middle;
  background: #eee;
  border: 3px solid #eee;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  color: #888;
  -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
  -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
  -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}
.contact-box textarea,
.contact-box textarea.form-control {
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 30px;
}
.contact-box input[type="text"]:focus,
.contact-box textarea:focus,
.contact-box textarea.form-control:focus {
  outline: 0;
  background: #fff;
  -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}
.contact-box input[type="text"]::-moz-placeholder,
.contact-box textarea::-moz-placeholder,
.contact-box textarea.form-control::-moz-placeholder { color: #bbb; font-style: italic; }
.contact-box input[type="text"]:-ms-input-placeholder,
.contact-box textarea:-ms-input-placeholder,
.contact-box textarea.form-control:-ms-input-placeholder { color: #bbb; font-style: italic; }
.contact-box input[type="text"]::-webkit-input-placeholder,
.contact-box textarea::-webkit-input-placeholder,
.contact-box textarea.form-control::-webkit-input-placeholder { color: #bbb; font-style: italic; }
.contact-box label {
  font-weight: 300;
}
.contact-box .has-error input[type="text"],
.contact-box .has-error input[type="url"],
.contact-box .has-error input[type="password"],
.contact-box .has-error select,
.contact-box .has-error textarea {
  border-color: #aa0000;
}
.contact-box .has-feedback label~.form-control-feedback {
  top: 40px;
}
