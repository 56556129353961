.navbar {
  margin-bottom: 0;
  border: 0;
  -o-transition: all .6s;
  -moz-transition: all .6s;
  -webkit-transition: all .6s;
  -ms-transition: all .6s;
  transition: all .6s;
}
.navbar.navbar-no-bg {
  background: none;
}
ul.navbar-nav {
  font-size: 16px;
  color: #fff;
}
.navbar-inverse ul.navbar-nav li a {
  color: #fff;
  opacity: 0.8;
  border-bottom: 1px solid transparent;
}
.navbar-inverse ul.navbar-nav li a:hover {
  color: #fff;
  opacity: 1;
  border-bottom: 1px solid transparent;
}
.navbar-inverse ul.navbar-nav li a:focus {
  color: #fff;
  outline: 0;
  opacity: 1;
  border-bottom: 1px solid transparent;
}
.navbar-inverse ul.navbar-nav li a.btn-link-2 {
  height: auto;
  margin: 4px 0 0 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #fff;
}
.navbar-inverse ul.navbar-nav li a.btn-link-2:hover,
.navbar-inverse ul.navbar-nav li a.btn-link-2:focus,
.navbar-inverse ul.navbar-nav li a.btn-link-2:active,
.navbar-inverse ul.navbar-nav li a.btn-link-2:active:focus {
  outline: 0;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.navbar-inverse .navbar-brand {
  cursor: pointer;
}
.navbar-brand {
  width: 260px;
  text-indent: -99999px;
}
.navbar-brand-lt {
  background: url('./logo.png') left center no-repeat;
}
.navbar-brand-lc {
  background: url('./logo-lc.png') left center no-repeat;
}
