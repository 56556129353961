.more-features-2-container { padding-top: 80px; }
.more-features-2-box { text-align: left; }
.more-features-2-box ul { margin-top: 15px; padding: 0; list-style: none; line-height: 36px; }
.more-features-2-box li i { margin-right: 10px; color: #ca8bdc; }
.more-features-2-box a.btn { margin: 15px 0 0 0; }
.many-options-container { padding-top: 80px; }
.many-options-box { text-align: left; }
.many-options-box-right { text-align: right; }
.many-options-box a.learn-more { border-bottom: 1px dotted; }
.many-options-box a.learn-more:hover, .many-options-box a.learn-more:focus { color: #555; border: 0; }
