body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #888;
  line-height: 30px;
  text-align: center;
}

strong { font-weight: 500; }

a, a:hover, a:focus {
  color: #f87b00;
  text-decoration: none;
  -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

h1, h2 {
  margin-top: 10px;
  font-size: 38px;
  font-weight: 100;
  color: #555;
  line-height: 50px;
}

h3 {
  font-size: 22px;
  font-weight: 300;
  color: #555;
  line-height: 30px;
}

img { max-width: 100%; }

.section-container {
  margin: 0 auto;
  padding-bottom: 80px;
}

.section-container-gray-bg {
  background: #f8f8f8;
}

.section-container-image-bg,
.section-container-image-bg h2,
.section-container-image-bg h3 {
  color: #fff;
}

.section-container-image-bg .divider-1 span {
  background: #fff;
  opacity: 0.8;
}

.section-description {
  margin-top: 60px;
  padding-bottom: 10px;
}

.section-description p {
  margin-top: 20px;
  padding: 0 120px;
}
.section-container-image-bg .section-description p {
  opacity: 0.8;
}

.section-bottom-button {
  padding-top: 60px;
}

.divider-1 span {
  display: inline-block;
  width: 200px;
  height: 1px;
  background: #ddd;
}
