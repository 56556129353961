.how-it-works { padding-bottom: 30px; }
.how-it-works-box { padding-top: 15px; padding-bottom: 15px; }
.how-it-works-box h3 { margin-top: 25px; }
.how-it-works-box p { opacity: 0.8; }
.how-it-works-container {
  background-image: url('../TopContent/backgrounds/4.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
