footer {
  padding: 60px 0 35px 0;
  background: #333;
  color: #ccc;
  text-align: left;
}
footer h3 {
  margin-top: 0;
  color: #fff;
}
.footer-contact-info p {
  word-wrap: break-word;
}
.footer-contact-info svg {
  padding-right: 10px;
  font-size: 28px;
  color: #555;
  padding-top: 8px;
}
.footer-copyright {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #555;
}
.footer-menu {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #555;
  text-align: right;
}
.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-menu li {
  display: inline;
  margin-left: 20px;
}
.footer-menu li div {
  display: inline-block;
  color: #fff;
  cursor: pointer;
}
