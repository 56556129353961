.inner-bg { padding: 200px 0 240px 0; }
.top-content .text { color: #fff; }
.top-content .text h1 { color: #fff; }
.top-content .description { margin: 20px 0 10px 0; }
.top-content .description p { opacity: 0.8; }
.top-buttons { margin-top: 40px; }
.top-buttons a.btn { min-width: 180px; }
.top-content {
  background-image: url('../TopContent/backgrounds/1.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
